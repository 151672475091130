
import { Routes, Route,Switch } from 'react-router-dom';
import About from '../pages/About';
import Hero from '../pages/Hero';
import AboutSection from '../pages/AboutSection';
import HomeButton from '../components/HomeButton';
import Gallary from '../pages/gallary';
import Services from '../pages/services';
import ServicesItem from '../pages/ServiceItem';
const AppRoutes = () => {
    return (
        <Routes>
            <Route index  path='/' element={<div className='h-full'>
            <Hero/> <AboutSection/> <Services/> <Gallary/>  <HomeButton/></div>}/>
            <Route path='/about' element={<div className='h-full'><About /></div>} />
            <Route path='/services/stationery' element={<div className='h-full'><ServicesItem/></div>} />
        </Routes>
    )
}

export default AppRoutes;