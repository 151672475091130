import React from 'react'
import { LanguageContext } from '../Context/LanguageContext';
import { useContext } from 'react';
import FooterInfo from '../components/FooterInfo';
import location_icon from '../assets/icons/location_icon.png';
import mail_icon from '../assets/icons/mail_icon.png';
import tel_icon from '../assets/icons/tel_icon.png';
import facebook_icon from '../assets/icons/facebook.png';


export const Footer = () => {
  const { texts ,language} = useContext(LanguageContext);


  return (
    
<footer id='contact' class="p-4 bg-slate-800  md:pr-6 md:pl-6 mt:pr-6 md:pb-4 md:py-8 w-full m-auto text-white">
    <div class="max-w-[1300px] m-auto flex flex-col items-center md:justify-center justify-start">
           <h1 className='font-semibold text-lg md:w-[800px] text-center w-full mt-5'>{texts.footer_text}</h1>
           <div className=' flex  flex-wrap md:w-[800px] w-full items-center   justify-start  mt-12 mb-10'>
              <FooterInfo text={texts.location_iraq} icon={location_icon} language={language}/>
              <FooterInfo text={texts.location_china} icon={location_icon} language={language}/>
              <FooterInfo text={'Iraq : 0770 152 2678 - 0770 157 1773 - 0770 158 5552 - 0770 155 4414'} icon={tel_icon} language={language}/>
              <FooterInfo text={'China : 0086-85594295 - 0086-18606571721'} icon={tel_icon} language={language}/>
              <FooterInfo text={'info@baranibahar.com'} icon={mail_icon} language={language}/>

           </div>
    </div>

    
    <hr class=" border-primary sm:mx-auto max-w-[1300px]  mt-5 mb-5" />

      <div class="max-w-[1300px] m-auto flex  items-center justify-center">
         <span class="block   sm:text-center text-md font-serif" dir='ltr'>
           ©{(new Date().getFullYear())} <a target="_blank" href="https://baranibahar.com" class="hover:underline">Barani Bahar Company™</a>. All Rights Reserved.
         </span>
      </div>

</footer>

  )
}
