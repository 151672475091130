import React, { useEffect, useState } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import { useContext } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Container } from "@nextui-org/react";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import AOS from "aos";
import "aos/dist/aos.css";
import st_bg from "../assets/BG/st_bg.jpg";
import img_3 from "../assets/qrtasya/1.jpg";
import img_4 from "../assets/qrtasya/2.jpg";
import img_5 from "../assets/qrtasya/3.jpg";
import img_6 from "../assets/qrtasya/4.jpg";
import img_7 from "../assets/qrtasya/5.jpg";
import img_8 from "../assets/qrtasya/6.jpg";
import img_9 from "../assets/qrtasya/7.jpg";
import img_10 from "../assets/qrtasya/8.jpg";
import img_11 from "../assets/qrtasya/9.jpg";
import img_12 from "../assets/qrtasya/10.jpg";
import img_13 from "../assets/qrtasya/11.jpg";
import img_14 from "../assets/qrtasya/12.jpg";
import img_15 from "../assets/qrtasya/13.jpg";
import img_16 from "../assets/qrtasya/14.jpg";
import img_17 from "../assets/qrtasya/15.jpg";
import img_18 from "../assets/qrtasya/16.jpg";
import img_19 from "../assets/qrtasya/17.jpg";
import img_20 from "../assets/qrtasya/18.jpg";
import img_22 from "../assets/qrtasya/20.jpg";
import img_23 from "../assets/qrtasya/21.jpg";
import img_25 from "../assets/qrtasya/23.jpg";
import img_26 from "../assets/qrtasya/24.jpg";

function ServicesItem() {
  const { texts } = useContext(LanguageContext);

  let images = [
    { src: img_3 },
    { src: img_4 },
    { src: img_5 },
    { src: img_6 },
    { src: img_7 },
    { src: img_8 },
    { src: img_9 },
    { src: img_10 },
    { src: img_11 },
    { src: img_12 },
    { src: img_13 },
    { src: img_14 },
    { src: img_15 },
    { src: img_16 },
    { src: img_17 },
    { src: img_18 },
    { src: img_19 },
    { src: img_20 },
    { src: img_22 },
    { src: img_23 },
    { src: img_25 },
    { src: img_26 },
  ];

  const settings = {
    className: "center",
    dots: true,
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    slidesPerRow: 1,
    initialSlide: 2,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  const [index, setIndex] = useState(-1);
  const [isOpen, setModalOpen] = useState(false);
  const setIsOpen = (state, i) => {
    setIndex(i);
    setModalOpen(state);
  };


  useEffect(() => {
    AOS.init();
  }, []);




  return (
    <div className='w-full bg-white flex justify-center items-center'>
    <div className=''>
        <div className='w-full h-[600px] bg-cover relative mt-24'>
          <img className="w-full h-[600px] z-[1] brightness-50 object-cover absolute" src={st_bg} alt="Slideshow" />

          <div className='max-w-[1250px] h-full flex flex-col justify-between relative z-[2]'>
            <h1 className='text-white mt-5 ml-5 mr-5 text-lg font-semibold'><a href='/'>{texts.services}</a>{' > ' + texts.stationery}</h1>
            <div className='flex flex-col justify-center items-center mb-5 ml-5 mr-5'>
              <h1 className='text-white mt-5 ml-5 text-3xl font-bold'>{texts.stationery}</h1>
              <h1 className='text-white mt-5 text-lg font-semibold text-center md:w-[50%] w-full'>{texts.stationery_subtitle}</h1>
            </div>
          </div>
        </div>
      

      <div className="w-full h-96 flex flex-col items-center justify-center ">
        <Slider {...settings} className='lg:w-[1200px] xsm:w-80 2xsm:w-72 w-52'>
          {images.map((item, index) => (
            <div className="card w-full" key={index} onClick={() => setIsOpen(true,index)}>
              <div className="card-top">
                <img
                  src={item.src}
                  alt={'baranibahar'}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <Lightbox
        index={index}
        slides={images}
        open={isOpen}
        plugins={[Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
        close={() => setIsOpen(false)}
        styles={{
          thumbnail: {
            borderColor: "#3498db",
          },
        }}
      />

</div>
    </div>
  );
}

export default ServicesItem;
